import React, { useEffect, useState } from 'react';

import SwitchField from './switch.field';
import { IQuestion } from 'app/interfaces/questionnaire';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const EntryContent: React.FC<IProps> = (props: any) => {
  const { disabled = false, questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);

  useEffect(() => {
    try {
      const filtered = questions.filter((question: IQuestion) => question.category === 'entry')

      setFilter(filtered);
    } catch (error) {
      console.log(error);
    }
  }, [questions])

  const classField = "col-12 mb-1 style-questions-responses";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled={disabled} />
      </div>
    )
  })

  return (
    <>
      {!!filters.length && (
        <>
          <h3 className="m-2">При входе: </h3>

          <div className="flex flex-wrap col-12 p-0">
            {rerender}
          </div>
        </>
      )}
    </>
  );
};

export default EntryContent;