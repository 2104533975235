import React, { useEffect, useState } from 'react';

import { useForm } from 'react-final-form';
import { Dialog } from 'primereact/dialog';
import SwitchField from './switch.field';
import { IQuestion } from 'app/interfaces/questionnaire';
import ImageViewer from 'components/imageViewer';

interface IProps {
  questions: IQuestion[];
}

export const FarewellContent: React.FC<IProps> = (props: any) => {
  const { questions } = props;
  const [ show, setShow ] = useState(false);
  const [ imgIndex, setImgIndex ] = useState(0);
  const [ photos, setPhotos ] = useState<string[]>([])
  const [ _photos, _setPhotos ] = useState<string[]>([])
  const [ filters, setFilter ] = useState<IQuestion[]>([]);
  const form = useForm ();

  const handleZoomImg = (fileNames: string[], index: number) => {
    _setPhotos(fileNames.map( (i:string) => `/api/admin/files/receipts/${i}` ))
    setImgIndex(index)
    setShow(true);
  };

  useEffect(() => {
    const photos = form.getState().values.photos;
    if(photos && photos.length > 0){
      setPhotos(JSON.parse(photos))
    }
  }, [form.getState().values.photos]); // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'farewell')
    
    setFilter(filtered);
  }, [questions])
  
  const classField = "p-col-12 p-md-6 p-xl-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled={true}/>
      </div>
    )
  })

  return (
    <>
      <h3 className="m-2">Расчёт прощание: </h3>

      <div className="flex flex-wrap col-12 p-0">
        
        {rerender}

        <div className="col-12">
        <h3>Приложенный чек</h3>
          <div
            onClick={()=>handleZoomImg([form.getState().values.receipt], 0)}
            style={{cursor: "zoom-in"}}
          >
            <img src={`/api/admin/files/receipts/${form.getState().values.receipt}`} alt="" width="325px"
              onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
          </div>
        </div>

        {photos.length ? (
          <div className="col-12 ">
            <h3>Дополнительные фотографии</h3>
            <div className="flex flex-wrap">
              {photos.map( (i:any, index: any) => (
                <div
                  onClick={()=>handleZoomImg(photos, index)}
                  style={{cursor: "zoom-in", width: '103px', height: '103px', margin: '.25rem'}}
                  className=''
                  key={index}
                >
                  <img 
                    src={`/api/admin/files/receipts/${i}`} 
                    alt="" 
                    className={'review_photo'}
                    onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
                </div>
              ))}
            </div>
            
          </div>
        ) : (
          <div className="col-12 ">
            <h3>Дополнительные фотографии</h3>
            <span>Фотографии отсутствуют</span>
            
          </div>
        )}

      </div>

      {/* <Dialog visible={show} style={{minWidth: '383px'}} onHide={()=>setShow(false)}>
        <img src={urlImage} alt="" onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
      </Dialog> */}

      <ImageViewer
        items={_photos}
        startImage={imgIndex}
        show={show}
        onHide={()=>setShow(false)}
      />
    </>
  );
};

export default FarewellContent;