import NewQuestionWin from "pages/shopper/windows/new.question.win";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import ListLogPage from "./list.log.page";

export const MainSettingsPage: React.FC = (props: any) => {
  const [ visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const handleAdd = () => setVisibleAdd(true);

  return (
    <>
    <TabView>
      <TabPanel header="Дополнительно" leftIcon="pi pi-dollar">
        <>
          <p><b>Тайный покупатель.</b> Создайте необходимый для вас "Вопрос". </p>
          <Button label="Создать вопрос" onClick={handleAdd} className="p-button-raised p-button-success mr-1" disabled/>

          <p>В разработке</p>
          {/* <NewQuestionWin visible={visibleAdd} setVisible={setVisibleAdd} /> */}
        </>
      </TabPanel>
      <TabPanel header="События" leftIcon="pi pi-briefcase">
        <ListLogPage />
      </TabPanel>
    </TabView>

    
    </>
  );
}

export default MainSettingsPage;

