import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import TableQuestionnaire from "features/questionnaire/table.questionnaire";
import QuestionnaireWin from "./windows/questionnaire.win";
import { useGetQuestionnairesMutation } from "app/services/questionnaire";
import NewQuestionnaireWin from "./windows/new.questionnaire.win";

export const ListQuestionnairePage: React.FC = () => {
  const [ currentId, setCurrentId] = useState<string | null>(null)
  const [ visible, setVisible] = useState<boolean>(false)
  
  const [ visibleAddQ, setVisibleAddQ] = useState<boolean>(false)
  const [ getQuestionnaires ] = useGetQuestionnairesMutation()

  
  const handleAddQuestionnaire = () => setVisibleAddQ(true);
  const handleRefresh = () => getQuestionnaires(null);
  const handleView = (id: string) => {
    setCurrentId(id)
    setVisible(true)
  }

  return (
    <>

      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <div className="flex flex-wrap">
          <Button label="Создать анкету" onClick={handleAddQuestionnaire} className="p-button-raised p-button-success mr-1 hidden"/>
            
          <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
        </div>
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <div className="card">
          <TableQuestionnaire onViewing={handleView} />
        </div>

      </ScrollPanel>

      <QuestionnaireWin visible={visible} setVisible={setVisible} currentId={currentId} />
      
      <NewQuestionnaireWin visible={visibleAddQ} setVisible={setVisibleAddQ}/>
    </>
  );
}

export default ListQuestionnairePage;