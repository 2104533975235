import React from "react";

import { Dialog } from "primereact/dialog";
import { useWindows } from "hooks/useWindows";
import { useDispatch } from "react-redux";
import { hide, show } from "features/windows/slice";
import { useMediaQuery } from "react-responsive";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { CheckboxField, KladrField, SelectField, TextAreaField, TextField } from "components/form/fields";
import { composeValidators, fullAddressValidator, requiredValidator } from "components/form/validators";

import { useGetCategoriesRestaurantQuery } from "app/services/requisites";
import { useCreateRestaurantMutation, useGetRestaurantsMutation } from "app/services/restaurants";
import { useRestaurants } from "hooks/useRestaurants";
import { ScrollPanel } from "primereact/scrollpanel";

interface IProps {
  setElementId?: (id: string) => void;
}

const initialState = {
  name: "",
  category: null,
  address: null,
  isAdultContent: false
}

const formatResponse = (data: any) => {
  return {
    name: data.name,
    categoryId: data.category?.id,
    city: {
      name: data.address?.data.city,
      region: data.address?.data.region_kladr_id
    },
    address: `${data.address.value.replace(`${data.address?.data.city_with_type}, `, '')}`,
    averageReceipt: +data.addInfo.averageReceipt,
    isAdultContent: data.isAdultContent,
    description: data?.description ? data.description : null
  }
}

export const AddRestaurantWin: React.FC<IProps> = (props: IProps) => {
  const { setElementId } = props;
  const dispatch = useDispatch()
  const windows = useWindows()
  const [createRestaurant] = useCreateRestaurantMutation()
  const { filter } = useRestaurants()
  
  const [ getRestaurants ] = useGetRestaurantsMutation()
  const { data } = useGetCategoriesRestaurantQuery(null)
  
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleClose = () => dispatch(hide({type: 'restaurants', name: 'add'}));
  const onSubmit = (values: any) => {
    createRestaurant( formatResponse(values) )
      .unwrap()
      .then((r: any) => {
        handleClose();
        dispatch(show({type: 'restaurants', name: 'edit'}));
        if(setElementId) setElementId(r._value._value);
        return;
      })
      .then(()=> getRestaurants(filter))
      .catch( e => console.log(e))
  }

  return (
    <Dialog 
      header="Новое заведение" 
      visible={windows.restaurants.add} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
      } : {
        maxWidth: "480px"
      } }
      onHide={handleClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialState}
        render={({ handleSubmit }) => (
          
            <form onSubmit={handleSubmit} >
              <ScrollPanel style={{maxHeight: '455px'}}>
                <div className="flex flex-wrap">
                  <div className="col-12 p-0 flex flex-wrap">
                    
                    <div className="col-12 pb-0">
                      <div className="pt-2">
                        <Field
                          validate={composeValidators(requiredValidator)}
                          name="name" label="Наименование:" render={TextField}
                          settings={{
                            placeholder: "Введите название заведения",
                          }}
                        />
                      </div>

                      <div className="pt-2">
                        <Field
                          validate={composeValidators(requiredValidator)}
                          name="category" label="Категории:" render={SelectField}
                          settings={{
                            options: data,
                            optionLabel: "name", 
                            display: "chip" 
                          }}
                        />
                      </div>

                      <div className="pt-2">
                        <Field
                          validate={composeValidators(requiredValidator, fullAddressValidator)}
                          name="address"
                          label="Адрес:"
                          render={KladrField}
                          settings={{
                            placeholder: "Введите адрес заведения",
                          }}
                        />
                      </div>

                      <div className="pt-2">
                        <Field
                          validate={composeValidators(requiredValidator)}
                          name="addInfo.averageReceipt" 
                          label="Средний чек на человека:" 
                          render={TextField}
                          settings={{
                            placeholder: "Введите возможную сумму чека",
                            keyfilter: "int"
                          }}
                        />
                      </div>

                      <div className='pt-2 text-center pb-2'>
                        <Field
                          name="isAdultContent"
                          render={CheckboxField}
                          inputId="isAdultContent"
                        />
                        <label className="pl-2" htmlFor="isAdultContent">
                          содержит контент +18
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-12">
                    <Field
                      name="description" label="Описание:" render={TextAreaField}
                      settings={{
                        placeholder: "Дополнительная информация",
                        rows:3,
                      }}
                    />
                  </div>
                </div>
              </ScrollPanel>

              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2">
                <div className="col-12 md:col-6 xl:col-3">
                  <Button label="Создать" className="col-12 p-button-raised p-button-success "/>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                  <Button label="Отмена" className="col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
                </div>
              </div>

            </form>
        )}
      />
    </Dialog>
  );
}

export default AddRestaurantWin;
