import React, { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMediaQuery } from "react-responsive";
import { toCategorySchemaTemplate } from "components/toStatus";
import { useGetQuestionnairesMutation } from "app/services/questionnaire";
import { Button } from "primereact/button";
import { useQuestionnaires } from "hooks/useQuestionnaires";
import { useCategories } from "hooks/useCategories";
import { useGetCategoriesQuestionnaireMutation } from "app/services/categories";
import { select } from './slice';
import { useDispatch } from "react-redux";
import { IQuestionnaire } from "app/interfaces/questionnaire";

interface IProps {
  isSelect?: boolean;
  onViewing: (id: string) => void;
  filters?: { categories: string[] };
}

export const TableQuestionnaire: React.FC<IProps> = (props: any) => {
  const { isSelect = false, onViewing, filters = {categories: []} } = props;
  const dispatch = useDispatch()
  const [ _questionnaires, setQuestionnaires ] = useState<IQuestionnaire[]>([])
  const { questionnaires, selected } = useQuestionnaires()
  const [ getQuestionnaires, { isLoading } ] = useGetQuestionnairesMutation()
  const { categoriesQuestionnaire } = useCategories()
  const [ getCategoriesQuestionnaire ] = useGetCategoriesQuestionnaireMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => { getQuestionnaires(null) }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { getCategoriesQuestionnaire(null) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(filters.categories.length) {
      const _filter: IQuestionnaire[] = []
      for(let i = 0; i < filters.categories.length; i++) {
        
        questionnaires
          .filter( (q) => {
            if(filters.categories[i] === "Ресторан") return q.category?.name === 'Рестораны'
            if(filters.categories[i] === "Кофейня") return q.category?.name === 'Кофейни'
            if(filters.categories[i] === "Кальянная") return q.category?.name === 'Кальянные'
            return false;
          } )
          .forEach( (r) => _filter.push(r) )

          setQuestionnaires(_filter)
      }
      
    }else {
      setQuestionnaires(questionnaires)
    }
    
  }, [questionnaires, filters])

  const handleViewing = (id: string) => { onViewing(id) }

  const action = (rowData: any) => (
    <div className="col-12 p-0 flex justify-content-end">
      <Button icon="pi pi-info-circle" className="p-button-rounded p-button-info " onClick={() => handleViewing(rowData.id)}></Button>
    </div>
  )

  return (
    <>
      <DataTable value={_questionnaires} 
        scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 200px)" : ""} 
        responsiveLayout="stack" breakpoint="768px" 
        className="table-mobile-rows" stripedRows loading={isLoading}
        emptyMessage={`Список анкет для заведения категории "${filters.categories.join(", ")}" пуст`}
        selection={selected}
        onSelectionChange={(e) => dispatch(select(e.value)) }
      >
        {isSelect && (
          <Column
            selectionMode="single"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
        )}
        <Column 
          field="name" header="Наименование"
          sortable sortField="name"
        ></Column>

        <Column 
          header="Тип анкеты" body={(rowData) => {
            const _filter = categoriesQuestionnaire.filter( (r: any) => r.id === rowData.categoryId)
            if(_filter.length) return toCategorySchemaTemplate(_filter[0].name);
              return <></>
            }}
          sortable sortField="categoryId" 
          style={{ maxWidth: '10rem' }}
        ></Column>    
            
        <Column body={action} style={{ maxWidth: '5rem' }}></Column>
      </DataTable>
    </>
  );
}

export default TableQuestionnaire;