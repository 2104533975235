import React from "react";
import { Dialog } from "primereact/dialog";
import GalleryComponent from "./gallery";

const ImageViewer: React.FC<any> = (props: any) => {
  console.log(props.items)
  return (
    <Dialog
      visible={props.show}
      modal
      className="windowItemViewer"
      onHide={() => props.onHide(false)}
    >
      <GalleryComponent
        items={props.items}
        className="h-100"
        startImage={props.startImage}
        onClick={(event: any) => event.stopPropagation()}
      />
    </Dialog>
  );
}

export default ImageViewer;