import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Virtual, Navigation, Pagination } from 'swiper/modules';

const GalleryComponent: React.FC<any> = (props: any) => {
  const {className, items, startImage = 1} = props;
  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const [ activeIndex, setActiveIndex ] = useState<number>(0);
  
  useEffect(() => {
    if (controlledSwiper) controlledSwiper.slideTo(startImage, 0);
    setActiveIndex(startImage)
  }, [controlledSwiper, startImage]);

  let data = items.map((item: any, index: any) => {
    return (
      <SwiperSlide
        key={index}
        className="ItemViewer-gallery--slide"
        zoom={true}
        virtualIndex={index}
      >
        <div
          style={{
            backgroundImage: `url(${item})`,
          }}
        ></div>
      </SwiperSlide>
    );
  });

  return (
    <div className={className}>
      <Swiper
        className="p-col-12 p-p-0 h-100 style-swiper"
        modules={[Controller, Virtual, Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        grabCursor={true}
        virtual
        pagination={{
          clickable: true,
        }}
        onSwiper={setControlledSwiper}
        controller={{ control: controlledSwiper }}
        onSlideChange={(e: any) => setActiveIndex(e.activeIndex)}
      >
        {data}
      </Swiper>
    </div>
  );
}

export default GalleryComponent;
