import React, { useEffect, useState } from 'react';

import { Field, useForm } from 'react-final-form';
import { TextAreaField } from 'components/form/fields';
import SwitchField from './switch.field';
import { IQuestion } from 'app/interfaces/questionnaire';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const ImpressionContent: React.FC<IProps> = (props: any) => {
  const { disabled = false, questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);
  const form = useForm();
  const state = form.getState().values;

  useEffect(() => {
    try {
      const filtered = questions.filter((question: IQuestion) => question.category === 'impression')

      setFilter(filtered);
    } catch (error) {
      console.log(error);
    }
  }, [questions])

  const classField = "col-12 mb-1 style-questions-responses";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={question.type === 'textArea' ? 'p-col-12' : classField} key={question.id}>
        <SwitchField question={question} disabled={disabled} />
      </div>
    )
  })

  return (
    <>
      <h3 className="m-2">Впечатление о посещении: </h3>

      <div className="flex flex-wrap col-12 p-0">

        {rerender}

        {(state.review && state.review.length > 0) && (
          <div className="col-12">
            <Field
              name="review"
              label="Отзыв"
              render={TextAreaField}
              settings={{
                disabled: disabled
              }}
            />
          </div>
        )}

        {(state.comment && state.comment.length > 0) && (
          <div className="col-12">
            <Field
              name="comment"
              label="Комментарий владельца"
              render={TextAreaField}
              settings={{
                disabled: disabled
              }}
            />
          </div>
        )}
        
      </div>
    </>
  );
};

export default ImpressionContent;