import React from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { Field, Form } from "react-final-form";
import { TextAreaField } from "components/form/fields";
import { composeValidators, minLengthValidator, requiredValidator } from "components/form/validators";
import { Button } from "primereact/button";
import { usePutCommentTaskMutation } from "app/services/tasks";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  setRefresh: () => void;
  item: any
}

export const ResponseCommentWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible, setRefresh, item } = props;
  const [putCommentTask] = usePutCommentTaskMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const onSubmit = (values: any) => {
    putCommentTask({ id: item.id, comment: values.comment })
      .unwrap()
      .then( () => { 
          setRefresh() 
          setVisible(false) 
        }
      )
  }

  if(!item) return <></>

  return (
    <Dialog 
      header={`Комментарий к отзыву`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        // height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={() => { 
        setVisible(false) 
      }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            <div className="col-12">
              <Field
                validate={composeValidators(requiredValidator, minLengthValidator(64))}
                name="comment"
                label="Комментарий владельца"
                render={TextAreaField}
              />
            </div>

            <div className="col-12">
              <Button label="Отправить" className=" col-12 p-button-raised p-button-info ml-1" />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
}

export default ResponseCommentWin;
