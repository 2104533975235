import React, { useEffect } from "react";

import { Chip } from 'primereact/chip';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMediaQuery } from "react-responsive";
import { toStatusOrder, toTypeOrder } from "components/toStatus";
import { useCoupons } from "hooks/useCoupons";
import { useGetCouponsMutation } from "app/services/coupons";
import { Button } from "primereact/button";
import { toDateNoTime } from "utils/toDate.util";
import { orderBy } from "lodash";

interface IProps {
  onViewing: (id: string) => void;
}

export const TableOrders: React.FC<IProps> = (props: any) => {
  const { onViewing } = props;
  const { coupons } = useCoupons()
  const [ getCoupons, { isLoading } ] = useGetCouponsMutation()
  
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => { getCoupons(null) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleViewing = (id: string) => { onViewing(id) }

  const action = (rowData: any) => (
    <div className="col-12 p-0 flex justify-content-end">
      <Button icon="pi pi-info-circle" className="p-button-rounded p-button-info " onClick={() => handleViewing(rowData.id)}></Button>
    </div>
  )

  const nameTemplate = (restaurant: any, active: boolean, date: undefined | string) => (
    <div className="flex flex-column">
      <span >
        <strong>{restaurant?.name}</strong> - 
        <i>{restaurant?.address}</i> 
        {active 
          ? <Chip label="Активирован" icon="pi pi-check" className="ml-2 chip-confirm" style={{ fontSize: '10px' }}/>
          : <Chip label="Не активирован" icon="pi pi-times" className="ml-2 chip-error" style={{ fontSize: '10px' }}/> 
        }
      </span>
      <span>{toDateNoTime(date ? date : new Date())}</span>
    </div>
  )

  const toStatusTemplate = (status: string) => <span className={`task-status task-status-${status}`}>{toStatusOrder(status)}</span>
  const toTypePayment = (type: string) => <span className={`task-status task-type-${type}`}>{toTypeOrder(type)}</span>
  
  return (
    <>
      <DataTable value={orderBy(coupons, ['createdAt'], ['desc'])} 
        scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 228px)" : ""} 
        responsiveLayout="stack" breakpoint="768px" 
        className="table-mobile-rows" stripedRows loading={isLoading}
      >
        <Column field="restaurant.name" header="Заведение"
          body={(rowData) => nameTemplate(rowData?.restaurant, rowData?.state === 'confirm', rowData?.createdAt)}
          sortable sortField="restaurant.name"
        ></Column>
              
        <Column 
          field="startedAt" header="Активация"
          body={(rowData)=>toDateNoTime(rowData.startedAt)}
          sortable sortField="startedAt"
          style={{ maxWidth: '8rem' }}
        ></Column>
        
        <Column field="order.order.state" header="Статус" body={(rowData)=>toStatusTemplate(rowData.order.state)} 
          sortable sortField="order.state"
          style={{ maxWidth: '7rem' }}
        ></Column>

        <Column field="order.typePayment" header="Тип оплаты" 
          body={(rowData)=>toTypePayment(rowData.order.typePayment)}
          style={{ maxWidth: '7rem' }}
        ></Column>
        <Column body={action} style={{ maxWidth: '3.35rem', padding: "0.25rem" }} ></Column>
      </DataTable>
    </>
  );
}

export default TableOrders;