import React, { useEffect } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import { useGetTasksMutation } from "app/services/tasks";
import { Badge } from 'primereact/badge';
import { toStatusTask } from "components/toStatus";
import { toDateNoTime } from "utils/toDate.util";
import { orderBy } from "lodash";

interface IProps {
  onViewing: (id: string) => void;
  filters?: any 
}

export const TableTasks: React.FC<IProps> = (props: any) => {
  const { onViewing, filters = {
    'state': { value: null, matchMode: 'equals' }
  } } = props;

  const [getTasks, { data, isLoading }] = useGetTasksMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(()=>{ getTasks(null) }, [getTasks])

  const handleViewing = (id: string) => { onViewing(id) }

  const action = (rowData: any) => (
    <div className="col-12 p-0 flex justify-content-end">
      {rowData.state === 'confirm' && (
        <Button 
          icon="pi pi-info-circle" 
          className="p-button-rounded p-button-info " 
          onClick={() => handleViewing(rowData.id)}
        ></Button>
      )}
      
    </div>
  )

  const toStatusTemplate = (status: string) => (<span className={`task-status task-status-${status}`}>{toStatusTask(status)}</span>)

  const nameTemplate = (restaurant: any, raffles: number) => (
    <span >
      <strong>{restaurant?.name}</strong> - 
      <i>{restaurant?.address}</i> 
      {raffles ? (
        <Badge value={raffles} className="ml-2" severity="success"></Badge>
      ) : <></>}
      
    </span>
  )

  return (
    <>
      <DataTable value={orderBy(data, ['updatedAt'], ['desc'])} 
        scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 200px)" : ""} 
        responsiveLayout="stack" breakpoint="768px" 
        className="table-mobile-rows" dataKey="id" filters={filters}
        stripedRows loading={isLoading} 
        // header={header}
        emptyMessage={`Список заданий пуст`}
      >
        
        <Column field="name" header="Заведение - адрес"
          body={(rowData) => 
            nameTemplate(
              rowData?.restaurant, 
              rowData?.raffles ? rowData.raffles.length : 0
            )}
          sortable sortField="name"
          style={{ maxWidth: 'calc(100% - 3rem)' }}
        ></Column>
        
        <Column 
          field="state" header="Статус"
          body={(rowData)=>toStatusTemplate(rowData.state)}
          sortable sortField="state"
          filterMatchMode="contains"
          style={{ maxWidth: '8rem' }}
        ></Column>
        
        <Column 
          field="startedAt" header="Активация"
          body={(rowData)=>toDateNoTime(rowData.startedAt)}
          sortable sortField="startedAt"
          style={{ maxWidth: '8rem' }}
        ></Column>
            
        <Column body={action} style={{ maxWidth: '5rem' }}></Column>
      </DataTable>
    </>
  );
}

export default TableTasks;